function apiBasePath() {
  // const publicPath = process.env.VUE_APP_PUBLIC_PATH;
  const apiPath = process.env.VUE_APP_RZ_API_PATH;

  // if (publicPath && !(apiPath.startsWith('http'))) {
  //   apiPath = `${publicPath}${apiPath}`;
  // }

  return apiPath;
}

const Config = {
  appUrlPrefix: process.env.VUE_APP_URL_PREFIX || '',
  rzAppKey: process.env.VUE_APP_RZ_APP_KEY,
  rzApiKey: process.env.VUE_APP_RZ_API_KEY,
  rzLoginPath: process.env.VUE_APP_RZ_LOGIN_PATH,
  rzLogoutPath: process.env.VUE_APP_RZ_LOGOUT_PATH,
  rzApiPath: apiBasePath(),
  gtagID: process.env.VUE_APP_GTAG_ID || 'UA-31087175-5',
  gtmID: process.env.VUE_APP_GTM_ID || 'GTM-NPB58LT',
  env: process.env.NODE_ENV,
  version: process.env.APP_VERSION || '1.0.0',
  cricketApiHost: '//www.cricketapi.com',
  sportsHost: process.env.VUE_APP_SPORTS_SITE_URL || 'https://sports.dev.roanuz.com/',
  consoleHost: process.env.VUE_APP_CONSOLE_HOST || 'https://console.roanuz.com/',
  playgroundHost: process.env.VUE_APP_V5API_HOST || 'https://api.sports.roanuz.com/v5/core/',
  legacyConsoleHost: process.env.VUE_APP_LEGACY_CONSOLE_HOST || 'https://console.sports.roanuz.com/',
  loginURL: process.env.REACT_APP_RZ_LOGIN_URL || 'https://sports.dev.roanuz.com/backend/business/login/',
  falconURL: process.env.REACT_APP_RZ_FALCON_URL || 'https://falcon.sports.dev.roanuz.com/',
};

export default Config;
