<template>
  <router-view/>
  <all-svg></all-svg>
</template>
<script>
import '@/assets/styles/global/styles.scss';
import AllSvg from '@/components/Svg/AllSvg.vue';

export default {
  components: {
    AllSvg,
  },
};
</script>
