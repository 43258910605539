import { createApp } from 'vue';
import moment from 'moment';
import RequestState from '@/components/RequestState.vue';
import App from './App.vue';
import router from './router';
import store from './store';

const app = createApp(App);

/* eslint-disable no-param-reassign */
app.config.globalProperties.$filters = {
  formatDate(date, dateFormatStr = 'Do MMM YYYY') {
    if (date) {
      return moment.unix(date).format(dateFormatStr);
    }
    return '';
  },
  format(date, dateFormatStr = 'MMM DD, YYYY') {
    if (date) {
      return moment.unix(date).format(dateFormatStr);
    }
    return '';
  },
  relativeShortDate(date, formatter = null) {
    const dateFormatStr = formatter || 'MMM DD, YYYY';
    if (date) {
      const shortDate = this.getMomentDate(date);
      return shortDate.format(dateFormatStr);
    }
    return '';
  },
  duration(endDate) {
    const end = moment.unix(endDate);
    const todaysdate = moment();
    return end.diff(todaysdate, 'days');
  },
  formatMonth(date) {
    const dateFormatStr = 'MMM YYYY';
    if (date) {
      const shortDate = this.getMomentDate(date);
      return shortDate.format(dateFormatStr);
    }
    return '';
  },

  formatWithoutYear(date) {
    const dateFormatStr = 'MMM DD';
    if (date) {
      const shortDate = this.getMomentDate(date);
      return shortDate.format(dateFormatStr);
    }
    return '';
  },

  utcLocalDate(date) {
    const dateFormatStr = 'MMM DD, YYYY, h:mm a';
    if (date) {
      const shortDate = this.getMomentDate(date).local();
      return shortDate.format(dateFormatStr);
    }
    return '';
  },

  timeStamp(date) {
    const dateFormatStr = 'MMM DD, YYYY';
    return moment(date).format(dateFormatStr);
  },

  getMomentDate(date) {
    if (moment.isMoment(date)) {
      return date;
    }

    if (date) {
      if (Number.isNaN(date)) {
        return moment(date);
      }

      return moment.unix(date);
    }

    return date;
  },

  isBeforeDate(date) {
    const givendate = this.getMomentDate(date)?.format('YYYY-MM-DD');
    const currentdate = moment().format('YYYY-MM-DD');
    if (moment(givendate).isBefore(currentdate)) {
      return true;
    }
    return false;
  },
};

app.component('rz-request-state', RequestState);
app.use(store).use(router).mount('#app');
