import axios from 'axios';
import Config from '@/config';
import { Enum } from '@/core/utils/enum';
import BaseType from '@/store/modules/base';
import CookieManager from '@/core/utils/cookieManager';

let apiInstance = null;

export const RequestStateStatus = new Enum({
  NotInit: 'Not Initiated',
  Loading: 'Loading',
  Init: 'InitialState',
  Loaded: 'Loaded',
  Error: 'Error',
  Cancelled: 'Cancelled',
  NotExist: 'No Projects Available',
});

export class RequestState extends BaseType {
  constructor(status) {
    super();
    this.status = status || RequestStateStatus.NotInit;
    this.ref = null;
    this.msg = null;
  }

  static typeName() {
    return 'RequestState';
  }
}

export const API = {
  forApikeyHeaderKey: 'rz-api-key',
  forAppkeyHeaderKey: 'rz-app-key',

  instance() {
    if (apiInstance == null) {
      const defaultHeaders = {};
      const token = CookieManager.getItem('rzAccessToken');
      defaultHeaders['RZ-ACCESS-TOKEN'] = token;
      apiInstance = axios.create({
        baseURL: Config.rzApiPath,
        headers: defaultHeaders,
      });

      apiInstance.interceptors.request.use((_config) => {
        const config = _config;
        config.params = config.params || {};
        config.params[API.forApikeyHeaderKey] = Config.rzApiKey;
        config.params[API.forAppkeyHeaderKey] = Config.rzAppKey;
        return config;
      });

      this.prepareAPI(apiInstance);
    }
    return apiInstance;
  },

  prepareAPI() {
    apiInstance.interceptors.response.use((response) => response, (error) => {
      if (error.response && error.response.status === 403) {
        if (CookieManager.hasItem('rzAccessToken')) {
          CookieManager.removeItem('rzAccessToken');
        }
      }
      return Promise.reject(error);
    });
  },

};
