import { createStore } from 'vuex';
import user from './modules/user';
import project from './modules/project';
import admin from './modules/admin';

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user, project, admin,
  },
});
