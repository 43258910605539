<template>
  <div class="page-request-state" v-if="(!state.status.isLoaded) && (!state.status.isNotInit)">
    <div v-if="state.status.isLoading" class="loading-msg">
      loading..
    </div>
    <error-state v-else-if="state.status.isError" :state="state"></error-state>
    <div v-else>State: {{state}}</div>
</div>
</template>
<style lang="scss" scoped>
  .page-request-state{
    .loading-msg {
      text-align: center;
      img {
        margin-right: 10px;
      }
    }
  }
</style>
<script>
import ErrorState from '@/components/ErrorState.vue';

export default {
  name: 'RequestState',
  props: {
    state: Object,
  },
  components: {
    'error-state': ErrorState,
  },
};
</script>
