import { createRouter, createWebHistory } from 'vue-router';
import CookieManager from '@/core/utils/cookieManager';
import store from '@/store';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/login-response',
    name: 'LoginResponse',
    component: () => import('../views/LoginResponse.vue'),
  },
  {
    path: '/v2/',
    name: 'Base',
    component: () => import('../views/Base.vue'),
    redirect: {
      name: 'dashboard',
    },
    children: [
      {
        path: '/v2/',
        name: 'dashboard',
        component: () => import('../views/Dashboard.vue'),
      },
      {
        path: 'project/:projectKey/',
        name: 'ProjectPage',
        component: () => import('../views/Dashboard.vue'),
      },
      {
        path: 'project/:projectKey/usage-report/',
        name: 'usageReport',
        component: () => import('../views/UsageReport.vue'),
      },
      {
        path: 'project/:projectKey/billing/',
        name: 'billing',
        component: () => import('../views/Billing.vue'),
      },
      {
        path: 'project/:projectKey/push-configuration/',
        name: 'pushConfiguration',
        component: () => import('../views/PushConfiguration.vue'),
      },
      {
        path: 'project/:projectKey/settings/',
        name: 'settings',
        component: () => import('../views/Settings.vue'),
      },
      {
        path: 'project/:projectKey/circleji-console/',
        name: 'circleji',
        component: () => import('../views/Circleji.vue'),
      },
      {
        path: 'project/:projectKey/articles/',
        name: 'articles',
        component: () => import('../views/Articles.vue'),
      },
      {
        path: 'project/:projectKey/fantasy-game',
        name: 'fantasyGame',
        component: () => import('../views/FantasyGame.vue'),
      },
      {
        path: 'project/:projectKey/invoice/:invoicekey/',
        name: 'invoice',
        component: () => import('../views/Invoice.vue'),
      },
      {
        path: 'project/:projectKey/transaction/:transactionkey/',
        name: 'transaction',
        component: () => import('../views/Invoice.vue'),
      },
      {
        path: 'project/:projectKey/profile',
        name: 'profile',
        component: () => import('../views/Profile.vue'),
      },
      {
        path: '/:pathMatch(.*)',
        name: 'pagenotfound',
        component: () => import('../views/http404.vue'),
      },
    ],
  },
  {
    path: '/v2/admin',
    name: 'Admin',
    component: () => import('../views/admin/Base.vue'),
    redirect: {
      name: 'adminDashboard',
    },
    meta: {
      isAdminView: true,
    },
    children: [
      {
        path: '/v2/',
        name: 'adminDashboard',
        component: () => import('../views/admin/Dashboard.vue'),
      },
      {
        path: 'projects',
        name: 'adminProjects',
        component: () => import('../views/admin/ProjectsList.vue'),
      },
      {
        path: 'projects/:type/:key/preview/',
        name: 'adminQuickProjectPreview',
        component: () => import('../components/admin/QuickPreviewProjects.vue'),
      },
      {
        path: 'projects/:key/preview/',
        name: 'adminProjectPreview',
        component: () => import('../components/admin/QuickPreviewProjects.vue'),
      },
      {
        path: 'invoice-list/:type/:key/preview/',
        name: 'adminQuickInvoicePreview',
        component: () => import('../components/admin/QuickPreviewInvoices.vue'),
      },
      {
        path: 'invoice-list/:key/preview/',
        name: 'adminInvoicePreview',
        component: () => import('../components/admin/QuickPreviewInvoices.vue'),
      },
      {
        path: 'transaction-list/:type/:key/preview/',
        name: 'adminQuickTransactionPreview',
        component: () => import('../components/admin/QuickPreviewTransaction.vue'),
      },
      {
        path: 'transaction-list/:key/preview/',
        name: 'adminTransactionPreview',
        component: () => import('../components/admin/QuickPreviewTransaction.vue'),
      },
      {
        path: 'users-info/:key/preview/',
        name: 'adminUserPreview',
        component: () => import('../components/admin/QuickPreviewUser.vue'),
      },
      {
        path: 'projects-legacy',
        name: 'adminProjectsLegacy',
        component: () => import('../views/admin/Projects.vue'),
      },
      {
        path: 'invoice-list',
        name: 'invoiceList',
        component: () => import('../views/admin/InvoiceList.vue'),
      },
      {
        path: 'transaction-list',
        name: 'transactionList',
        component: () => import('../views/admin/TransactionList.vue'),
      },
      {
        path: 'usage-report',
        name: 'adminUsageReport',
        component: () => import('../views/admin/UsageReport.vue'),
      },
      {
        path: 'users-info',
        name: 'adminUsersInfo',
        component: () => import('../views/admin/UsersInfo.vue'),
      },
      {
        path: 'discount-info',
        name: 'adminDiscountInfo',
        component: () => import('../views/admin/DiscountInfo.vue'),
      },
      {
        path: 'coupon-info',
        name: 'adminCouponInfo',
        component: () => import('../views/admin/CouponInfo.vue'),
      },
      {
        path: 'reports',
        name: 'adminReports',
        component: () => import('../views/admin/Reports.vue'),
      },
      {
        path: 'sales-process-report',
        name: 'adminSalesProcessList',
        component: () => import('../views/admin/SalesProcessList.vue'),
      },
      {
        path: 'package-list',
        name: 'adminPackageList',
        component: () => import('../views/admin/CreatePackage.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }

    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const isAdminView = to.meta.isAdminView || false;
  store.commit('user/updateIsAdminView', { isAdminView });

  if (to.name !== 'Login' && to.name !== 'LoginResponse') {
    const token = CookieManager.getItem('rzAccessToken');
    if (token && store) {
      next();
    } else {
      next({ name: 'Login', query: { next_url: to.fullPath } });
    }
  } else {
    next();
  }
});

export default router;
