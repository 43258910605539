// import Vue from 'vue';
import { reactive } from 'vue';
import moment from 'moment';

import { API, RequestState, RequestStateStatus } from '@/api/core';
import CookieManager from '@/core/utils/cookieManager';
import BaseType from './base';

export class User extends BaseType {
  constructor() {
    super();
    this.loaded = false;
  }

  get haveStaffRights() {
    const yes = this.groups
      && (
        this.groups.indexOf('staff') > -1
        || this.groups.indexOf('admin') > -1
      );
    return yes;
  }

  static createFromJson(raw) {
    let data = new User();
    data = Object.assign(data, raw);
    data.loaded = true;
    return data;
  }

  static fetch(userKey) {
    const params = { key: userKey };
    return API.instance()
      .get('/business/user_info/', { params })
      .then((resp) => User.createFromJson(resp.data.data));
  }

  static fetchBootApi() {
    return API.instance()
      .get('/boot/')
      .then((resp) => User.createFromJson(resp.data.data));
  }

  static editUserDetails(details) {
    return API.instance()
      .post('/business/user/actions/', {
        action: 'edit_user_data',
        key: details.key,
        name: details.name,
        company_name: details.company,
      })
      .then((resp) => User.createFromJson(resp.data.data));
  }

  static typeName() {
    return 'User';
  }

  static resendVerification(action) {
    return API.instance()
      .post('/business/user/actions/',
        { action })
      .then((resp) => {
        if (resp.status === '200') {
          return true;
        }
        return false;
      }).catch((err) => {
        console.log('verification error', err);
      });
  }

  static signOut() {
    return API.instance()
      .get('/business/user/logout/')
      .then((resp) => resp)
      .catch((err) => {
        console.error('sign out error', err);
      });
  }
}

const initState = () => reactive({
  isLoggedIn: false,
  isAdminView: true,
  accessToken: null,
  boot: new User(),
  activeUser: new User(),
  activeUserState: new RequestState(),
});

const getters = {};

const actions = {
  fetchActiveUser({ commit }) {
    return new Promise((resolve, reject) => {
      const token = CookieManager.getItem('rzAccessToken');
      commit('updateActiveUserStatus', { status: RequestStateStatus.Loading });

      User.fetch().then((data) => {
        commit('updateActiveUser', { data });
        commit('updateAccessToken', { token });
        commit('updateActiveUserStatus', { status: RequestStateStatus.Loaded });
        resolve(data);
      }).catch((err) => {
        console.log('Error while fetching user ', err);
        commit('updateActiveUserStatus', { status: RequestStateStatus.Error, msg: err });
        reject(err);
      });
    });
  },
  getInstance({ dispatch, state }) {
    if (state.instance) {
      return Promise.resolve(state.instance);
    }

    return dispatch('fetchInstance');
  },

  fetchInstance({ commit }) {
    return User.fetchBootApi().then((data) => {
      commit('updateInstance', { data });
    });
  },

  updateAccessToken({ commit }, { token }) {
    const expires = moment().add(24, 'hours');
    CookieManager.setItem('rzAccessToken', token, expires.toDate());
    commit('updateAccessToken', { token });
    return Promise.resolve();
  },
};

const mutations = {
  updateInstance(state, { data }) {
    state.boot = data;
  },

  updateAccessToken(state, { token }) {
    // Vue.set(state, 'accessToken', token);
    state.accessToken = token;
  },

  updateIsAdminView(state, { isAdminView }) {
    // Vue.set(state, 'accessToken', token);
    state.isAdminView = isAdminView;
  },

  updateActiveUser(state, { data }) {
    // Vue.set(state, 'activeUser', data);
    state.activeUser = data;
  },

  updateActiveUserStatus(state, { status, msg }) {
    state.activeUserState.status = status;
    state.activeUserState.ref = msg;
  },
};

export default {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
};
