import { Enum } from '@/core/utils/enum';
import { API } from '@/api/core';
import Config from '@/config';

export const Currencies = new Enum({
  INR: 'INR',
  USD: 'USD',
  EUR: 'EUR',
});

export const CurrencyObjs = new Enum({
  INR: {
    key: 'INR',
    symbol: '₹',
  },
  USD: {
    key: 'USD',
    symbol: '$',
  },
  EUR: {
    key: 'EUR',
    symbol: '€',
  },
});

export const InvoiceStatus = new Enum({
  Invoice: {
    value: 'invoice',
    label: 'Invoice',
  },
  Receipt: {
    value: 'receipt',
    label: 'Receipt',
  },
  CancelledInvoice: {
    value: 'cancelled_invoice',
    label: 'Cancelled Invoice',
  },
});

export const TransactionStatus = new Enum({
  Draft: {
    value: 'draft',
    label: 'Draft',
  },
  Validated: {
    value: 'validated',
    label: 'Validated',
  },
  Cancelled: {
    value: 'cancelled',
    label: 'Cancelled',
  },
});

export const sellerInformation = {
  CompanyName: 'Roanuz Softwares Private Limited',
  CIN: 'U72900TN2012PTC086867',
  PAN: 'AAFCR9714G',
  GSTIN: '33AAFCR9714G1ZC',
  SwiftCode: 'HDFCINBBCHE',
  SacCode: '00440452 (Support Plans)',
  AccNo: '00102560001150',
  IfscCode: 'HDFC000010',
  Beneficiary: 'Roanuz Softwares Private Limited',
  GateNo: '4th Floor, 284/1B, OMR',
  Area: 'Perungudi, Chennai',
  PinCode: '600096',
};

export const PaymentStatus = new Enum({
  WaitingForPayment: {
    value: 'waiting_for_payment',
    label: 'Waiting for Payment',
  },
  PaymentSuccess: {
    value: 'payment_transaction_success',
    label: 'Paid',
  },
  PaymentFailed: {
    value: 'payment_transaction_failed',
    label: 'Billing Issue',
  },
  PaymentNotRequired: {
    value: 'payment_not_required',
    label: 'Payment not required',
  },
  PaymentRefunded: {
    value: 'payment_refunded',
    label: 'Payment refunded',
  },
});

export class Transaction {
  constructor() {
    this.loaded = false;
  }

  get downloadLink() {
    // eslint-disable-next-line max-len
    return `/backend/rest/business/transaction/${this.key}/?as_pdf=1&rz-api-key=${Config.rzApiKey}&rz-app-key=${Config.rzAppKey}`;
  }

  static sendTransactionEmail(transactionkey) {
    return API.instance()
      .post(`/business/transaction/${transactionkey}/actions/`, {
        action: 'resend_transaction_mail',
      })
      .then((resp) => resp).catch((err) => err);
  }

  static createFromJson(raw) {
    let data = new Transaction();
    data = Object.assign(data, raw);
    if (raw.payment_status) {
      data.payment_status = PaymentStatus.parse(raw.payment_status);
    }
    if (raw.invoices) {
      for (let i = 0; i < raw.invoices.length; i += 1) {
        data.invoices[i].status = InvoiceStatus.parse(raw.invoices[i].status);
      }
    }
    if (raw.status) {
      data.status = TransactionStatus.parse(raw.status);
    }
    data.loaded = true;
    return data;
  }

  static fetchTransaction(transactionkey) {
    return API.instance()
      .get(`/business/transaction/${transactionkey}/`)
      .then((resp) => Transaction.createFromJson(resp.data.data)).catch(() => {
        console.log('erceipt info error');
      });
  }
}

export class Invoice {
  constructor() {
    this.loaded = false;
  }

  get downloadLink() {
    // eslint-disable-next-line max-len
    return `/backend/rest/business/invoice/${this.key}/?as_pdf=1&rz-api-key=${Config.rzApiKey}&rz-app-key=${Config.rzAppKey}`;
  }

  static sendInvoiceEmail(invoiceKey) {
    return API.instance()
      .post(`/business/invoice/${invoiceKey}/actions/`, {
        action: 'resend_invoice_mail',
      })
      .then((resp) => resp);
  }

  static fetchInvoice(invoiceKey) {
    return API.instance()
      .get(`/business/invoice/${invoiceKey}/`)
      .then((resp) => Invoice.createFromJson(resp.data.data));
  }

  static createFromJson(raw) {
    let data = new Invoice();
    data = Object.assign(data, raw);
    if (data.transactions) {
      const transactions = [];
      raw.transactions.forEach((ele) => {
        transactions.push(Transaction.createFromJson(ele));
      });
      data.transactions = transactions;
    }
    if (raw.status) {
      data.status = InvoiceStatus.parse(raw.status);
    }
    data.loaded = true;
    return data;
  }
}
