export default class BaseType {
  constructor() {
    // Its best to get the name from constructor but
    // the minified file does not carry the same name
    // https://github.com/webpack/webpack/tree/master/examples/scope-hoisting

    this.rzTypeName = this.constructor.typeName();
  }

  static typeName() {
    console.error('Must be defined');
  }

  static createFromJson(raw) {
    let data = new this();
    data = Object.assign(data, raw);
    return data;
  }
}
