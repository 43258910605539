<template>
  <div class="error-msg">
    <div v-if="state.ref && state.ref.response && state.ref.response.data.status">
      <div v-if="state.ref.response.data.status == 403">
        Access Forbidden on Resource
        <div v-if="state.ref.response.data.error && state.ref.response.data.error.msg">
          {{state.ref.response.data.error.msg}}
        </div>
      </div>
      <div v-else-if="state.ref.response.data.status == 404">
        Resource not found!
      </div>
      <div v-else-if="state.ref.response.data.error.status">
        {{state.ref.response.data.error.status}}: {{state.ref.response.data.error.code}} -
        {{state.ref.response.data.error.message}}
      </div>
      <div v-else>
        Error on resource
        {{state.ref.response}}
      </div>
    </div>
    <div v-else>
      Something wrong!
      <div v-if="state.ref">{{state.ref}}</div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .error-msg {
    font-size: 12px;
    text-transform: uppercase;
    color: #9b9b9b;
    font-weight: normal;
    background-color: #fff;
    margin: 5px 0;
    text-align: center;
    border: 1px solid #e9e9e9;
    padding: 25px 0 25px;
  }
</style>
<script>
export default {
  name: 'ErrorState',
  props: {
    state: Object,
  },
};
</script>
