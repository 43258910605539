import { reactive } from 'vue';
import Config from '@/config';
import { API, RequestState, RequestStateStatus } from '@/api/core';
import { Enum } from '@/core/utils/enum';
import moment from 'moment';
import BaseType from './base';

export const PaymentStatus = new Enum({
  WaitingForPayment: {
    value: 'waiting_for_payment',
    label: 'Waiting for Payment',
  },
  PaymentSuccess: {
    value: 'payment_transaction_success',
    label: 'Paid',
  },
  PaymentFailed: {
    value: 'payment_transaction_failed',
    label: 'Billing Issue',
  },
  PaymentRefunded: {
    value: 'payment_refunded',
    label: 'Payment refunded',
  },
});

export const TransactionPaymentStatus = new Enum({
  WaitingForPayment: {
    value: 'waiting_for_payment',
    label: 'Waiting for Payment',
  },
  PaymentSuccess: {
    value: 'payment_transaction_success',
    label: 'Paid',
  },
  PaymentTransactionStarted: {
    value: 'payment_transaction_started',
    label: 'Payment transaction started',
  },
  PaymentNotRequired: {
    value: 'payment_not_required',
    label: 'Payment not required',
  },
  PaymentFailed: {
    value: 'payment_transaction_failed',
    label: 'Billing Issue',
  },
  PaymentRefunded: {
    value: 'payment_refunded',
    label: 'Payment refunded',
  },
});

export const InvoiceStatus = new Enum({
  Invoice: {
    value: 'invoice',
    label: 'Invoice',
  },
  Receipt: {
    value: 'receipt',
    label: 'Receipt',
  },
  CancelledInvoice: {
    value: 'cancelled_invoice',
    label: 'Cancelled Invoice',
  },
});

export const TransactionStatus = new Enum({
  Draft: {
    value: 'draft',
    label: 'Draft',
  },
  Validated: {
    value: 'validated',
    label: 'Validated',
  },
  Cancelled: {
    value: 'cancelled',
    label: 'Cancelled',
  },
});

export const supportNumbers = {
  indianTollfree: {
    label: '000-800-040-1803',
    link: 'tel:0008000401803',
  },
  usTollfree: {
    label: '+1 (855) 2SPORT4',
    link: 'tel:+18552776784',
  },
  supportMail: {
    label: 'support@roanuz.com',
    link: 'mailto:support@roanuz.com',
  },
  managerMail: {
    label: 'divya.gurunath@roanuz.com',
    link: 'mailto:divya.gurunath@roanuz.com',
  },
};

export const ProjectPaymentStatus = new Enum({
  BillingVerified: {
    value: 'billing_verified',
    label: 'Billing Verified',
  },
  Paid: {
    value: 'paid',
    label: 'Paid',
  },
  Unpaid: {
    value: 'unpaid',
    label: 'Unpaid',
  },
  Unknown: {
    value: 'unknown',
    label: 'Unknown',
  },
});

export const ProjectVerificationStatus = new Enum({
  Paid: 'paid',
  Waiting: 'waiting',
});

export const ProjectStatus = new Enum({
  Active: 'active',
  InActive: 'inactive',
  Suspended: 'suspended',
  InactiveByPaymentPending: 'inactive_by_payment_pending',
  InactiveByTrailEnd: 'inactive_by_trail_end',
  Unknown: 'unknown',
});

export const ProjectLicenseKey = new Enum({
  Enterprise: 'rs_paygo_enterprise_a101',
  EnterpriseAnnual: 'rs_paygo_enterprise_a101_annual',
  EnterpriseC1x: 'rs_paygo_enterprise_c1x',
  Standard: 'rs_paygo_standard',
  Premium: 'rs_paygo_premium',
  PremiumAnnual: 'rs_paygo_premium_annual',
  Package: 'rs_package',
});

export class Invoice {
  static createFromJson(raw) {
    let data = new Invoice();
    data = Object.assign(data, raw);
    for (let i = 0; i < raw.length; i += 1) {
      data[i].status = InvoiceStatus.parse(raw[i].status);
    }
    return data;
  }
}

export class Transaction {
  static createFromJson(raw) {
    let data = new Transaction();
    data = Object.assign(data, raw);
    for (let i = 0; i < raw.length; i += 1) {
      data[i].status = TransactionPaymentStatus.parse(raw[i].payment_status);
    }
    return data;
  }
}

export class Project extends BaseType {
  constructor() {
    super();
    this.loaded = false;
    this.status = ProjectStatus.InActive;
    this.usage = null;
    this.hasValidLicense = false;
    this.billingInfo = null;
    this.resource_usage = {};
  }

  get setProfileLink() {
    return `${Config.sportsHost}user/project/${this.key}/wizard?step=billing`;
  }

  get setUpfrontLink() {
    return `${Config.sportsHost}user/project/${this.key}/wizard?step=prepay`;
  }

  get setCommittedUseLink() {
    return `${Config.sportsHost}user/project/${this.key}/wizard`;
  }

  // eslint-disable-next-line class-methods-use-this
  get setUserLogin() {
    return `${Config.sportsHost}user/login`;
  }

  get isNew() {
    if (this.loaded && this.hasValidLicense) {
      return false;
    }
    return true;
  }

  get billingCountry() {
    if (this.billing && this.billing.country_code) {
      return this.billing.country_code;
    }

    return '-';
  }

  get billingMedium() {
    if (this.billing) {
      return [
        this.billing.preferred_payment_gateway,
        this.billing.preferred_payment_medium,
      ].join('-');
    }

    return '-';
  }

  get billingMediumShort() {
    if (this.billing) {
      let pg = this.billing.preferred_payment_gateway || '';
      let pm = this.billing.preferred_payment_medium || '';
      if (pg) {
        pg = pg[0].toUpperCase();
      }
      if (pm) {
        pm = pm[0].toUpperCase();
      }
      return [
        pg,
        pm,
      ].join('-');
    }

    return '-';
  }

  get isBillingVerified() {
    return this.billing && this.billing.is_verified;
  }

  get requireBillingVerification() {
    return this.billing && !this.billing.is_draft && !this.billing.is_verified;
  }

  get hasBilling() {
    return this.billing && this.billing.key;
  }

  static createProjectLink() {
    return `${Config.sportsHost}user/project/create`;
  }

  static createFromJson(raw) {
    let data = new Project();
    data = Object.assign(data, raw);
    if (raw.status) {
      data.status = ProjectStatus.parse(raw.status);
    }
    if (raw.project_payment_status) {
      data.project_payment_status = ProjectPaymentStatus.parse(raw.project_payment_status);
    }
    if (raw.project_license && raw.project_license.length) {
      // eslint-disable-next-line prefer-destructuring
      data.project_license = raw.project_license[0];
      if (data.project_license.product) {
        data.project_license.license_key = ProjectLicenseKey.parse(data.project_license.product);
      }
      if (raw.project_license[0].is_active) {
        data.hasValidLicense = true;
      }
    } else {
      data.project_license = {};
    }
    data.loaded = true;
    return data;
  }

  static fetchProjects() {
    return API.instance()
      .get('/business/user/projects/list/')
      .then((resp) => {
        const { projects } = resp.data.data;
        const projectList = [];
        projects.forEach((project) => {
          projectList.push(Project.createFromJson(project));
        });
        return projectList;
      }).catch((err) => {
        console.log('fetch project error', err);
      });
  }

  static fetchBillHistory(params) {
    return API.instance()
      .get(`/business/project/${params.projectKey}/billing_history/?page=${params.page}&limit=${params.limit || 10}`)
      .then((resp) => {
        Invoice.createFromJson(resp.data.data.invoices);
        return resp.data.data;
      })
      .catch((err) => {
        console.log('fetch project error', err);
      });
  }

  static fetchTransactionHistory(query) {
    return API.instance()
      .get(
        `/business/project/${query.key}/transactions_history/?page=${query.page}
        &${query.params}&limit=${query.limit || 10}`,
      )
      .then((resp) => {
        Transaction.createFromJson(resp.data.data.transactions);
        return resp.data.data;
      }).catch((err) => {
        console.log('fetch project error', err);
      });
  }

  static fetchApiUsageDetails(params) {
    return API.instance()
      .get(`/business/project/${params.projectKey}/usage/${params.year}/${params.month}/`)
      .then((resp) => resp.data.data)
      .catch((err) => err);
  }

  static fetchUnpaidBillDetails(key) {
    return API.instance()
      .get(`/business/project/${key}/unpaid_billing_history/`)
      .then((resp) => resp.data.data)
      .catch((err) => err);
  }

  static fetchCashBalanceDetails(key) {
    return API.instance()
      .get(`/business/project/${key}/cash_balance/`)
      .then((resp) => resp.data.data)
      .catch((err) => err);
  }

  static fetchPackageKeys(key) {
    return API.instance()
      .get(`/business/project/${key}/package_products/active/`)
      .then((resp) => resp.data.data)
      .catch((err) => err);
  }

  static fetchResourceUsageDetails(params) {
    return API.instance()
      // eslint-disable-next-line max-len
      .get(`/business/project/${params.projectKey}/resource/${params.resourceKey}/usage/${params.year}/${params.month}/`)
      .then((resp) => {
        const { data } = resp.data;
        const keyName = Object.keys(data.day_wise_spending);
        for (let j = 0; j < keyName.length; j += 1) {
          keyName[j] = moment(keyName[j]).format('MMM DD');
        }
        const query = {};
        query.data = data;
        query.key = data.key;
        query.label = keyName;
        return query;
      });
  }

  static editProject(projectKey, projectInfo) {
    return API.instance()
      .put(
        `/business/project/${projectKey}/`,
        projectInfo,
      )
      .then((resp) => {
        if (resp.status === '200') {
          return true;
        }
        return false;
      }).catch((err) => {
        console.log('edit project error', err);
      });
  }

  delete() {
    return API.instance()
      .delete(`/business/project/${this.key}/`)
      .then((resp) => {
        if (resp.data && resp.data.data) {
          return true;
        }
        return false;
      }).catch((err) => {
        console.log('delete project error', err);
      });
  }

  static deleteProject(key) {
    return API.instance()
      .delete(`/business/project/${key}/`)
      .then((resp) => {
        if (resp.data && resp.data.data) {
          return true;
        }
        return false;
      }).catch((err) => {
        console.log('delete project error', err);
      });
  }

  postProjectActions(resetObj) {
    return API.instance()
      .post(`/business/project/${this.key}/actions/`, resetObj)
      .then((resp) => {
        if (resp.data && resp.data.good) {
          console.log('resp.data.data', resp.data.data);
          return resp.data.data;
        }
        return false;
      }).catch((err) => {
        console.log('delete project error', err);
      });
  }

  static fetch(projectKey) {
    return API.instance()
      .get(`/business/project/${projectKey}/`)
      .then((resp) => {
        if (resp.data && resp.data.good) {
          return Project.createFromJson(resp.data.data);
        }
        return false;
      }).catch((err) => {
        console.log('selected project error', err);
      });
  }

  getPushConfiguration() {
    return API.instance()
      .get(`/business/project/${this.key}/push_configs/`)
      .then((resp) => {
        if (resp.status === 200) {
          return resp.data.data;
        }
        return false;
      }).catch((err) => {
        console.log('error', err);
      });
  }

  static typeName() {
    return 'Project';
  }
}

export class ProjectList extends BaseType {
  constructor() {
    super();
    this.loaded = false;
    this.projects = null;
  }

  static typeName() {
    return 'ProjectList';
  }
}

const initState = () => reactive({
  activeProject: new Project(),
  activeProjectState: new RequestState(),
  projectList: new ProjectList(),
});

const getters = {};

const actions = {
  fetchProjects({ commit }) {
    return new Promise((resolve, reject) => {
      // commit('updateActiveProjectStatus', { status: RequestStateStatus.Loading });
      Project.fetchProjects().then((projects) => {
        if (projects && projects.length) {
          commit('updateProjectList', { projects });
          if (window.location.pathname === '/v2/') {
            commit('updateActiveProjectStatus', { status: RequestStateStatus.Init });
          }
          resolve(projects);
        } else {
          const err = { status: RequestStateStatus.NotExist, msg: 'No Projects Available' };
          commit('updateActiveProjectStatus', err);
          reject(err);
        }
      }).catch((err) => {
        commit('updateActiveProjectStatus', { status: RequestStateStatus.Error, msg: err });
        reject(err);
      });
    });
  },

  fetchProject({ commit }, projectKey) {
    return new Promise((resolve, reject) => {
      commit('updateActiveProjectStatus', { status: RequestStateStatus.Loading });
      Project.fetch(projectKey).then((project) => {
        if (project) {
          commit('updateActiveProject', project);
          commit('updateActiveProjectStatus', { status: RequestStateStatus.Loaded });
          resolve(project);
        } else {
          const err = { status: RequestStateStatus.Error, msg: 'No Access' };
          commit('updateActiveProjectStatus', err);
          reject(err);
        }
      }).catch((err) => {
        commit('updateActiveProjectStatus', { status: RequestStateStatus.Error, msg: err });
        reject(err);
      });
    });
  },

  fetchProjectUsage({ commit }, { params }) {
    return new Promise((resolve, reject) => {
      Project.fetchApiUsageDetails(params).then((usage) => {
        commit('updateProjectUsage', { usage });
        resolve(usage);
      }).catch((err) => {
        reject(err);
      });
    });
  },

  fetchResourceUsage({ commit }, { params }) {
    return new Promise((resolve, reject) => {
      Project.fetchResourceUsageDetails(params).then((data) => {
        commit('updateResourceUsage', { data });
        resolve(data);
      }).catch((err) => {
        reject(err);
      });
    });
  },

  fetchInvoiceHistory({ commit }, { params }) {
    return new Promise((resolve, reject) => {
      Project.fetchBillHistory(params).then((billingInfo) => {
        commit('updateBillingHistory', { billingInfo });
        resolve(billingInfo);
      }).catch((err) => {
        reject(err);
      });
    });
  },
  fetchTransactionHistory({ commit }, { query }) {
    return new Promise((resolve, reject) => {
      Project.fetchTransactionHistory(query).then((transactionInfo) => {
        commit('updateTransactionHistory', { transactionInfo });
        resolve(transactionInfo);
      }).catch((err) => {
        reject(err);
      });
    });
  },

  fetchUnpaidBillDetails({ commit }, { key }) {
    return new Promise((resolve, reject) => {
      Project.fetchUnpaidBillDetails(key).then((unpaidBillInfo) => {
        commit('updateUnpaidBills', { unpaidBillInfo });
        resolve(unpaidBillInfo);
      }).catch((err) => {
        reject(err);
      });
    });
  },
  fetchCashBalanceDetails({ commit }, { key }) {
    return new Promise((resolve, reject) => {
      Project.fetchCashBalanceDetails(key).then((cashDetails) => {
        commit('updateCashBalanceDetails', { cashDetails });
        resolve(cashDetails);
      }).catch((err) => {
        reject(err);
      });
    });
  },
};

const mutations = {
  updateProjectList(state, { projects }) {
    state.projectList.loaded = true;
    state.projectList.projects = projects;
    if (!state.activeProject.key) {
      // eslint-disable-next-line prefer-destructuring
      state.activeProject = projects[0];
      state.activeProjectState.status = RequestStateStatus.Loaded;
    }
  },

  updateActiveProject(state, project) {
    // const index = state.projectList.projects.findIndex((item) => item.key === project.key);
    // state.projectList.projects[index].last_viewed_on = project.last_viewed_on;
    // state.projectList.projects.sort((a, b) => ((a.last_viewed_on < b.last_viewed_on) ? 1 : -1));
    state.activeProject = project;
  },

  updateActiveProjectStatus(state, { status, msg }) {
    state.activeProjectState.status = status;
    state.activeProjectState.ref = msg;
  },

  updateProjectUsage(state, { usage }) {
    state.activeProject.usage = usage;
  },

  updateResourceUsage(state, { data }) {
    state.activeProject.resource_usage[data.key] = data;
  },

  updateBillingHistory(state, { billingInfo }) {
    state.activeProject.billingInfo = billingInfo;
  },

  updateTransactionHistory(state, { transactionInfo }) {
    state.activeProject.transactionInfo = transactionInfo;
  },

  updateUnpaidBills(state, { unpaidBillInfo }) {
    state.activeProject.unpaidBillInfo = unpaidBillInfo;
  },

  updateCashBalanceDetails(state, { cashDetails }) {
    state.activeProject.cashDetails = cashDetails;
  },

  updateProjectName(state, projectName) {
    state.activeProject.name = projectName;
  },
};

export default {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
};
